import { useQuery } from '@apollo/client';
import React from 'react';
import { Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import getProductsList from '../graphql/queries/getProductsList.graphql';
import Loader from './Loader';
import TrainingThumbnail from './TrainingThumbnail';
import PaginatedView from './PaginatedView';

export default function ETrainingsContent({ trainingType, page }) {
  const { data, loading } = useQuery(getProductsList, {
    variables: { type: 'training', page },
  });
  if (loading || !data) {
    return <Loader />;
  }
  const onlineTrainings = data.getProductsList.list;
  return (
    <PaginatedView
      linkName={'e-szkolenia/strona'}
      pagesCount={data.getProductsList.pages}
      currentPage={page}
    >
      <Flex flexDirection={'row'} color={'text'} flexWrap={'wrap'}>
        {onlineTrainings ? (
          onlineTrainings.map((training) => (
            <TrainingThumbnail
              data={training}
              key={training._id}
              type={trainingType}
            />
          ))
        ) : (
          <Loader />
        )}
      </Flex>
    </PaginatedView>
  );
}

ETrainingsContent.propTypes = {
  trainingType: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
};
