import { Box, Heading } from 'rebass/styled-components';
import Interweave from 'interweave';
import React from 'react';
import PropTypes from 'prop-types';

export default function SingleTrainingHeader({ title, content }) {
  return (
    <Box marginY={30} color={'text'} paddingY={[20, 30]}>
      <Heading fontSize={32} fontFamily={'Oswald'}>
        {title}
      </Heading>
      <Box className={'db-text'}>
        <Interweave content={content} />
      </Box>
    </Box>
  );
}

SingleTrainingHeader.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};
SingleTrainingHeader.defaultProps = {
  title: '',
  content: '',
};
