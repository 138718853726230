import React from 'react';
import PropTypes from 'prop-types';
import ETrainingsContent from '../../../components/ETrainingsContent';
import TrainingsPage from '../../../components/TrainingsPage';
import Layout from '../../../components/Layout';

const ETrainingsPage = ({ page }) => {
  return (
    <Layout>
      <TrainingsPage trainingType={'e-szkolenia'}>
        <ETrainingsContent
          trainingType={'e-szkolenia'}
          page={
            Number.isNaN(Number(page)) || Number(page) < 0 ? 0 : Number(page)
          }
        />
      </TrainingsPage>
    </Layout>
  );
};

ETrainingsPage.propTypes = {
  page: PropTypes.string.isRequired,
};

ETrainingsPage.defaultProps = {};

export default ETrainingsPage;
