import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Flex } from 'rebass/styled-components';
import React from 'react';
import pageSettingsQuery from '../graphql/queries/getPageSettings.graphql';
import Loader from './Loader';
import SingleTrainingHeader from './SingleTrainingHeader';

const pageSettingsVariable = {
  'szkolenia-online': 'online-training',
  'e-szkolenia': 'training',
  'szkolenia-otwarte': 'openTraining',
  'szkolenia-zamkniete': 'closedTrainings',
};

export default function TrainingsPage({ trainingType, children }) {
  const pageSettingsVariableElement = pageSettingsVariable[trainingType];
  const { data: pageData, loading: pageDataLoading } = useQuery(
    pageSettingsQuery,
    {
      variables: {
        type: 'pageContent',
        page: pageSettingsVariableElement,
      },
    },
  );
  if (pageDataLoading || !pageData) {
    return <Loader />;
  }
  return (
    <Flex variant={'wrapper'} flexDirection={'column'}>
      <SingleTrainingHeader
        title={pageData.getPageSettings.settings.title}
        content={pageData.getPageSettings.settings.content}
      />
      {children}
    </Flex>
  );
}

TrainingsPage.propTypes = {
  trainingType: PropTypes.string.isRequired,
  children: PropTypes.element,
};

TrainingsPage.defaultProps = {
  children: null,
};
